<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs"/>
      <section class="section">
        <FormRow>
          <template v-slot:label>ポイントカテゴリー</template>
          <template v-slot:content>
            <div class="form-content-row">
              <div class="form-select">
                <select
                  class="form-control form-select-input"
                  name="categoryId"
                  v-model="searchForm.categoryId"
                  @change="handleSearchCategory"
                >
                  <option value="">選択してください</option>
                  <option
                    v-for="item in categoriesList"
                    :key="item.id"
                    :value="item.id"
                  >{{ item.name }}</option>
                </select>
              </div>
            </div>
          </template>
        </FormRow>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox
                    ref="table"
                    :storeModule="storeModule"
                    :dataTable="dataTable"
                    :labels="labels"
                    @handle-toggle="handleStatus"
                    @select-items="handleSelectedItems"
                    :count="pointQRCount"
                  />
                </div>
              </template>
              <template v-slot:footer>
                <PaginationLog
                  ref="pagination"
                  :modulePath="modulePath"
                  :subdomain="subdomain"
                  :listLength="pointQRCount"
                  :searchConditions="searchConditions"
                ></PaginationLog>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <Modal @close="closeModal" v-if="modal">
      <template v-slot:body>
        <qrcode-vue
          class="qr"
          :value="item.encryptId.toString() || ''"
          :size="240"
          level="H"
        />
        <div class="qr-code">
          <p class="qr-title">カテゴリー</p>
          <p class="qr-num">{{ item.pointCategoryName }}</p>
        </div>
        <div class="qr-code">
          <p class="qr-title sub-title">タイトル</p>
          <p class="qr-num">{{ item.title }}</p>
        </div>
      </template>
      <template v-slot:footer>
        <ul class="listGrid justify-content-end">
          <li class="listGrid-item">
            <button
              class="btn btn-white"
              type="button"
              @click="closeModal"
            >
              閉じる
            </button>
          </li>
        </ul>
      </template>
    </Modal>
    <Modal @close="closeModal('deletedModal')" v-if="deletedModal">
      <template v-slot:headline>削除</template>
      <template v-slot:body>
        <p class="description text-align-center">
          対象のQRコードを削除しますか？
        </p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button
              class="btn btn-lg btn-white"
              type="button"
              @click="closeModal('deletedModal')"
            >
              キャンセル
            </button>
          </li>
          <li class="listGrid-item">
            <ActionButton
              class="btn btn-lg btn-red"
              :handle-submit="deletePointQRs"
              button-text="削除する"
            />
          </li>
        </ul>
      </template>
    </Modal>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter v-if="!isClientChild">
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <ActionButton :disabled="!isSelectedPointQrExisted" class="btn btn-red" :handleSubmit="showDeleteModal" buttonText="削除" />
          </li>
          <li class="listGrid-item  pos-end">
            <ActionButton :handle-submit="goToRegister" class="btn btn-main" button-text="新規登録" />
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <Modal @close="closeModal('statusModal', toggleFlag)" v-if="statusModal">
      <template v-slot:headline>{{ isPublic ? '公開' : '非公開' }}</template>
      <template v-slot:body>
        <p class="description text-align-center">
          {{
            isPublic
              ? 'このQRコードを公開しますか？'
              : 'このQRコードを非公開にしますか？'
          }}
        </p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button
              class="btn btn-lg btn-white"
              type="button"
              @click="cancelChangeStatus"
            >
              キャンセル
            </button>
          </li>
          <li class="listGrid-item">
            <ActionButton
              class="btn btn-lg btn-yellow"
              :handle-submit="changeStatus"
              :button-text="isPublic ? '公開する' : '非公開にする'"
            />
          </li>
        </ul>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import QrcodeVue from 'qrcode.vue';
// components
import Modal from '@/components/Modal.vue';
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import FormRow from '@/components/FormRow.vue';
//helpers
import { formatDateAndTime, formatLocalString } from '@/helpers/formatData';
//mixins
import nav from '@/mixins/nav/point';
import modal from '@/mixins/plugin/modal';
import search from '@/mixins/plugin/search';

export default {
  name: 'ShopQr',
  data: function() {
    return {
      pageName: 'ポイント',
      labels: [
        { isCheckbox: true },
        { key: 'title', name: '管理用タイトル' },
        { key: 'createDate', name: '登録日時' },
        { key: 'storeName', name: '店舗名' },
        { key: 'amount', name: 'ポイント数' },
        { key: 'quantity', name: '発行枚数' },
        { key: 'totalUsed', name: '利用回数' },
        { key: 'validFlag', name: '公開' },
        { isButtonColumn: true }
      ],
      parentModulePath: 'parentPoint/getPointQRList',
      childModulePath: 'childPoint/getPointQRList',
      selectedPointQR: {},
      searchConditions: {},
      searchFields: ['categoryId'],
      deletedModal: false,
      statusModal: false,
      isPublic: 0,
      qrId: null,
    };
  },
  components: {
    Modal,
    TableWithCheckbox,
    PaginationLog,
    QrcodeVue,
    FormRow,
  },
  mixins: [modal, nav, search],
  computed: {
    ...mapGetters({
      userInfo: 'auth/infor',
      parentPointQRList: 'parentPoint/pointQRList',
      childPointQRList: 'childPoint/pointQRList',
      parentPointQRCount: 'parentPoint/pointQRCount',
      childPointQRCount: 'childPoint/pointQRCount',
    }),
    pointQRCount() {
      return this.isPointCommon ? this.parentPointQRCount : this.childPointQRCount;
    },
    pointQRList() {
      return this.isPointCommon ? this.parentPointQRList : this.childPointQRList;
    },
    isSelectedPointQrExisted() {
      return this.selectedPointQR?.ids?.length > 0 || this.selectedPointQR.allFlag;
    },
    dataTable() {
      return (
        (this.pointQRList || []).map((item) => {
          return {
            ...item,
            title: {
              content: item.title,
              routeName: this.isPointCommon ? 'EventParentPointQREdit' : 'EventChildPointQREdit',
              params: { id: item.id },
              isLink: true,
            },
            storeName: item?.shop?.name || '事務局',
            createDate: formatDateAndTime(item.createDate),
            amount: formatLocalString(item.amount) + 'P',
            quantity: item?.quantity || '-',
            totalUsed: item?.totalUsed || '-',
            validFlag: {
              isToggleButton: true,
              value: item?.validFlag,
              id: item?.id,
              isDisabled: this.isClientChild
            },
            buttonLinks: [
              {
                content: 'QRコード',
                class: 'btn btn-sm btn-bd-main',
                function: () => this.openQRModal(item.id),
              },
            ],
          };
        })
      );
    },
  },
  mounted() {
    const loading = this.$loading.show();
    Promise.all([
      this.getCategories(),
    ]).finally(() => {
      this.$loading.clear(loading);
    })
  },
  methods: {
    toggleFlag() {
      this.pointQRList.find((el) => el.id === this.qrId).validFlag = !this.isPublic;
    },
    handleStatus(id, newStatus) {
      this.showModal('statusModal');
      this.qrId = id;
      this.pointQRList.find((el) => el.id === id).validFlag = newStatus;
      this.isPublic = newStatus ? 1 : 0;
    },
    async changeStatus() {
      const result = await this.$store.dispatch(`${this.storeModule}/updatePointQrStatus`, {
        subdomain: this.subdomain,
        id: this.qrId,
        data: { validFlag: this.isPublic },
      });
      this.closeModal('statusModal');
      if (result) this.$message.updated('pointQR');
      else this.toggleFlag();
    },
    cancelChangeStatus() {
      this.handleStatus(this.qrId, !this.isPublic);
      this.closeModal('statusModal');
    },
    async openQRModal(id) {
      const loading = this.$loading.show();
      const pointQRDetail = await this.$store.dispatch(`${this.storeModule}/getPointQRDetail`, {
        id: id,
        subdomain: this.subdomain
      });
      this.$loading.clear(loading);
      const category = (this.categoriesList || []).find(item => item.id === pointQRDetail.pointCategoryId)
      this.showModal(null, {
        ...pointQRDetail,
        pointCategoryName: category?.name || ''
      })
    },
    handleSelectedItems(items) {
      this.selectedPointQR = items;
    },
    showDeleteModal() {
      if (this.isSelectedPointQrExisted) {
        this.showModal('deletedModal');
      } else {
        this.$message.noSelectedItem();
      }
    },
    async deletePointQRs() {
      const result = await this.$store.dispatch(`${this.storeModule}/removePointQRs`, {
        ids: this.selectedPointQR.ids,
        allFlag: this.selectedPointQR.allFlag,
        searchCondition: {
          ...this.searchConditions,
        },
      });
      this.$refs.table.resetCheck();
      this.closeModal('deletedModal');
      if (result) {
        if (result.data.count) {
          this.$message.deleted('pointQR');
        } else {
          this.$message.showError('someoneUpdated');
        }
        this.$refs.pagination.resetPagination();
      }
    },
    handleSearchCategory() {
      this.search();
      if (this.pointQRList?.length) {
        this.$refs.table.resetCheck();
      }
    },
    goToRegister() {
      this.$router.push({ name: this.isPointCommon ? 'EventParentPointQRRegister' : 'EventChildPointQRRegister' })
    },
  },
};
</script>
<style lang="scss" scoped>
.qr-code {
  flex-wrap: nowrap;
  align-items: flex-start;
}
.qr-title {
  width: 86px;
  margin-right: 10px;
}

.sub-title {
  width: 72px !important;
}
</style>
